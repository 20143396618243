import React from "react"
import Layout from "../components/layout"
import { useLocation } from '@reach/router'
import ShareButtons from "../components/ShareButtons"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import ImageGallery from 'react-image-gallery'

import moment from 'moment'


import Seo from "../components/seo"
import { graphql, Link } from "gatsby"


const BlogTag = ({data}) => {
    const location = useLocation();
    const get_location = location.pathname.replace('/tag/','')
    const keyword = get_location.replace('-',' ');
    console.log('Single',keyword )
    //const post_detial = data.wpPost
    const PostByTags = data.allWpPost.edges
    console.log('post Tag', data)

    return (

        <Layout>

<Seo title="Post by Tag" />

<section className = "blog_listing">

<div className="container">

        <h1>Articles </h1>

<div className = "blog_listing_grid">


{PostByTags.map((tagpost) => (
         
         <Link to = {`/blog/${tagpost.node.slug}`} id = {tagpost.node.id} key = {tagpost.node.title}>
  <div className = "latest_post"  >
  
                        <div className="post_box">
                        <GatsbyImage
      image={getImage(tagpost.node.featuredImage.node.localFile)}
      quality = "100"
      alt={tagpost.node.title} />
<div className="post_inner">
<h2>{tagpost.node.title}</h2>

<div className="post_time">

<div className="time_slot">
  <i className="fa fa-calendar"></i>
  <span>{moment(tagpost.node.date).fromNow()}</span>      
  </div>

{
tagpost.node.excerpt ? 

<div className="time_slot">
<i className="fa fa-clock-o" ></i>
<span dangerouslySetInnerHTML={{ __html: tagpost.node.excerpt.replace( /(<([^>]+)>)/ig, '') }}></span>

</div>
: 
''
}


</div>
</div>



                        </div>
        
                </div>
                </Link>






       ))}





</div>

</div>


</section>

        </Layout>
    )

}


export const BlogPost_Tag = graphql`
query allPostTags($slug: String) {
    allWpPost(
      filter: {tags: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
    ) {
      edges {
                node {
                  id
                  title
                  slug
                 
                  excerpt
                  date(formatString: "YYYY-MM-DD")
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED,width:534)
                        }
                      }
                    }
                  }
                

                }
              }
    }
  }
  `

  export default BlogTag;